<template>
  <div>
    <b-button-group>
      <b-button variant="outline-primary" @click="expandAll">
        Tümünü Aç
      </b-button>
      <b-button variant="outline-primary" @click="collapseAll">
        Tümünü Kapat
      </b-button>
      <b-button variant="outline-primary" @click="exportDefault">
        Dışarı Aktar
      </b-button>
      <b-button variant="outline-primary" @click="exportFull">
        Tümünü Aktar
      </b-button>
    </b-button-group>
    <div ref="svgElementContainer"></div>
  </div>
</template>

<script>
import {OrgChart} from 'd3-org-chart';
import axios from "@/libs/axios";
import {avatarText, getApiFile} from "@core/utils/filter";
import {BButton, BButtonGroup} from "bootstrap-vue";

export default {
  components: {BButton, BButtonGroup},
  data() {
    return {
      chartReference: null,
      data: []
    };
  },
  watch: {
    data(value) {
      this.renderChart(value);
    }
  },
  created() {
    axios.get(`/organizationschemes`).then(response => {
      this.data = response.data.data;
    }).catch(error => {
    })
  },
  methods: {
    renderChart(data) {
      if (!this.chartReference) {
        this.chartReference = new OrgChart();
      }
      this.chartReference
          .container(this.$refs.svgElementContainer) // node or css selector
          .data(data)
          .nodeHeight((d) => 200)
          .nodeWidth((d) => 300)
          .childrenMargin((d) => 90)
          .compactMarginBetween((d) => 65)
          .compactMarginPair((d) => 100)
          .neightbourMargin((a, b) => 50)
          .siblingsMargin((d) => 100)
          .nodeContent(function (d, i, arr, state) {
            var rndColor = d.data.colorCode;
            var avatarHtml = '';
            if (d.data.userImage === null || d.data.userImage === '') {
              avatarHtml = '<span style="display: inline-flex;\n' +
                  '    align-items: center;\n' +
                  '    justify-content: center;\n' +
                  '    vertical-align: middle;font-weight: 600;\n' +
                  '    color: #fff;border-radius: 0.357rem !important;margin-top:-30px;margin-left:' + (d.width / 2 - 30) + 'px;border-radius:100px;width:60px;height:60px; background-color: ' + rndColor + '"><span style="font-size: calc(20px);"><span>' + avatarText(d.data.userName) + '</span></span></span>';
            } else {
              avatarHtml = '<img src="' + (getApiFile(d.data.userImage)) + '" style="margin-top:-30px;margin-left:' + (d.width / 2 - 30) + 'px;border-radius:100px;width:60px;height:60px;" />';
            }
            return `
            <div style="padding-top:30px;background-color:none;margin-left:1px;height:${d.height}px;border-radius:2px;overflow:visible">
              <div style="height:${d.height - 32}px;padding-top:0px;background-color:white;border:1px solid lightgray;">
                ${avatarHtml}
                <div style="margin-top:-30px;background-color:${rndColor};height:10px;width:${d.width - 2}px;border-radius:1px"></div>
                <div style="padding:20px; padding-top:35px;text-align:center">
                   <div style="color:#899499;font-size:16px;"> ${d.data.organizationName}</div>
                   <div style="color:#404040;font-size:16px;margin-top:8px"> ${d.data.positionName}</div>
                   <div style="color:#6774AD;font-size:16px;margin-top:8px;font-weight:bold"> ${d.data.userName} </div>
                </div>
              </div>
            </div>
  `;
          })
          .render();

      var compact = 0;
      this.chartReference.compact(!!(compact++ % 2)).render().fit()
    },
    expandAll() {
      this.chartReference.expandAll()
    },
    collapseAll() {
      this.chartReference.collapseAll()
    },
    exportDefault() {
      this.chartReference.exportSvg()
    },
    exportFull() {
      this.chartReference.expandAll()
      setTimeout(() => {
        this.chartReference.exportImg({full: true, scale: 5})
      }, 1000)
    }
  }
}
</script>


<style scoped lang="scss">

</style>